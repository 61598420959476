<template>
  <div class="container-fluid">
    <div class="row mb-3">
      <div class="col my-auto">
        <h5 class="text-primary fw-bold mb-0">
          Orders
          <span v-if="!loading && pagination">({{ pagination.total }})</span>
        </h5>
      </div>
      <div class="col-auto my-auto">
        <div v-if="$store.getters['auth/user'].nd_account_email" class="col text-end">
          <button
            @click="toSupplierPayments()"
            class="btn btn-outline-primary btn-sm"
          >
            <i class="far fa-credit-card me-1"></i>
            Supplier Payments
          </button>
        </div>
      </div>
    </div>

    <!-- Application Dashboard -->

    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-3 ms-auto my-auto">
            <input
              class="form-control form-control-sm onboarding-tour-step-6"
              type="text"
              v-model="searchTerm"
              @input="searchTermUpdate"
              placeholder="Search for clients, orders and more..."
              aria-label="Search for clients, orders and more..."
            />
          </div>

          <div class="col-auto my-auto">
            <button
              v-if="selectedOrders.length > 0"
              class="btn btn-primary btn-sm"
              :class="!canPaySelected ? 'disabled' : ''"
              :disabled="!canPaySelected"
            >
              <i class="far fa-credit-card-front me-2"></i>Pay Selected Orders
            </button>
          </div>

          <div class="col-auto my-auto">
            <select-user
              v-if="$can('view orders for other users')"
              v-model="userFilter"
            />
          </div>

          <div class="col-auto my-auto">
            <select class="form-control form-control-sm" v-model="statusFilter">
              <option value="" selected>Filter Status</option>
              <option value="">All</option>
              <option value="paid">Paid</option>
              <option value="not_paid">Not Paid</option>
              <option value="partial">Partial</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>
          <div class="col-auto my-auto">
            <select class="form-control form-control-sm" v-model="typeFilter">
              <option value="" selected>Filter Type</option>
              <option value="">All</option>
              <option value="standard">Standard</option>
              <option value="consultation">Consultation</option>
              <option value="custom">Custom</option>
              <option value="online_programme">Online Programme</option>
              <option value="historic">Historic</option>
            </select>
          </div>
          <div class="col-auto my-auto">
            <div class="dropleft d-inline">
              <button
                class="btn btn-outline-dark btn-sm dropdown-toggle"
                type="button"
                id="sortByDropDown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Sort by
              </button>
              <div class="dropdown-menu" aria-labelledby="sortByDropDown">
                <a class="dropdown-item" @click="sortOrdersBy('oldest-newest')"
                  >Oldest to Newest</a
                >
                <a class="dropdown-item" @click="sortOrdersBy('newest-oldest')"
                  >Newest to Oldest</a
                >
              </div>
            </div>
          </div>
          <div class="col-auto my-auto">
            <button class="btn btn-outline-dark btn-sm" @click="clearFilters">
              <i class="far fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="card-body bg-white">
        <loading
          v-if="loading || searchTermCheckerActive"
          class="my-4"
        ></loading>

        <table
          class="table"
          v-if="orders.length > 0 && !loading && !searchTermCheckerActive"
        >
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col" v-if="$can('view orders for other users')">
                Practitioner
              </th>
              <th scope="col">Client</th>
              <th scope="col">Type</th>
              <th scope="col">Status</th>
              <th scope="col">Total</th>
              <th scope="col">Outstanding</th>
              <th scope="col">Order Date</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody v-if="loadingOrders">
            <tr>
              <td colspan="10" class="text-center">
                <loading class="my-4"></loading>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              v-for="order in orders"
              :key="order.id"
              class="cursor-pointer"
              @click="goToOrder(order.id)"
            >
              <td>#{{ order.id }}</td>
              <td v-if="$can('view orders for other users')">
                {{ order.practitioner ? order.practitioner.name : "-" }}
              </td>
              <td>{{ order.client ? order.client.name : "-" }}</td>
              <td class="text-capitalize">
                {{ order.type | formatUnderScored }}
              </td>
              <td class="text-capitalize">
                <span
                  class="badge badge-pill text-capitalize"
                  :class="getOrderStatusColour(order)"
                >
                  {{ order.status | formatUnderScored }}</span
                >
              </td>
              <td>{{ order.currency.symbol }}{{ order.total.toFixed(2) }}</td>
              <td>
                {{ order.currency.symbol
                }}{{ order.amount_outstanding.toFixed(2) }}
              </td>
              <td>
                {{ order.created_at | formatDateTime }}
              </td>

              <td style="width: 1px; white-space: nowrap">
                <span v-if="order.is_overdue" class="badge bg-danger ms-3"
                  >Payment Overdue</span
                >
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="row my-4 text-center"
          v-if="orders.length == 0 && !loading && !searchTermCheckerActive"
        >
          <div class="col">
            <h5>There are no orders using that filter</h5>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col my-auto">
                <button
                  class="btn btn-sm btn-light"
                  @click="fetchOrders(pagination.first_page_url)"
                  :disabled="!pagination.first_page_url"
                >
                  First Page
                </button>

                <button
                  class="btn btn-sm btn-light ms-3"
                  @click="fetchOrders(pagination.prev_page_url)"
                  :disabled="!pagination.prev_page_url"
                >
                  Previous
                </button>
              </div>
              <div class="col my-auto text-center">
                <p class="mb-0">
                  Page
                  {{ pagination.current_page ? pagination.current_page : 1 }}
                  of
                  {{ pagination.last_page ? pagination.last_page : 1 }}
                </p>
              </div>
              <div class="col my-auto text-end">
                <button
                  class="btn btn-sm btn-light cursor-pointer me-3"
                  @click="fetchOrders(pagination.next_page_url)"
                  :disabled="!pagination.next_page_url"
                >
                  Next
                </button>

                <button
                  class="btn btn-sm btn-light cursor-pointer"
                  @click="fetchOrders(pagination.last_page_url)"
                  :disabled="!pagination.last_page_url"
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/views/components/loader/Loading";
import SelectUser from "@/views/components/users/SelectUser";

export default {
  components: { Loading, SelectUser },
  props: ["user"],
  data() {
    return {
      loading: true,
      loadingOrders: false,
      orders: [],
      pagination: {},
      searchTerm: "",
      searchTermCheckerActive: false,
      sorting: "",
      sortDirection: "desc",
      statusFilter: "",
      typeFilter: "",
      supplierFilter: null,
      userFilter: null,
      order: null,
      selectedOrders: [],
      canPaySelected: false,
      suppliers: [],
    };
  },
  watch: {
    selectedOrders(val, oldVal) {
      var checkSelectedOrders = this.selectedOrders.filter(function (o) {
        return o.status == "paid";
      });
      if (checkSelectedOrders && checkSelectedOrders.length > 0) {
        this.canPaySelected = false;
      } else {
        this.canPaySelected = true;
      }
    },
    statusFilter() {
      this.saveFilters();
      this.fetchOrders();
    },
    typeFilter() {
      this.saveFilters();
      this.fetchOrders();
    },
    userFilter() {
      this.saveFilters();
      this.fetchOrders();
    },
  },
  methods: {
    searchTermUpdate() {
      if (!this.searchTermCheckerActive) {
        this.fetchOrdersBasedOnSearchTerms();
        this.searchTermCheckerActive = true;
      }
    },
    fetchOrdersBasedOnSearchTerms() {
      setTimeout(() => {
        const seconds = new Date().getSeconds();
        if (seconds % 2 === 0) {
          this.searchTermCheckerActive = false;
          this.saveFilters();
          this.fetchOrders();
        } else {
          this.fetchOrdersBasedOnSearchTerms();
        }
      }, 1000);
    },
    saveFilters() {
      let filters = {
        status: this.statusFilter,
        type: this.typeFilter,
        searchTerm: this.searchTerm,
        user: { id: this.userFilter?.id, name: this.userFilter?.name },
      };
      this.$cookies.set("filters.orders", filters);
      console.log("saved:", filters);
    },
    loadFilters() {
      let filtersCookie = this.$cookies.get("filters.orders");
      if (filtersCookie) {
        this.statusFilter = filtersCookie.status;
        this.typeFilter = filtersCookie.type;
        this.searchTerm = filtersCookie.searchTerm;
        this.userFilter = {
          id: filtersCookie.user.id,
          name: filtersCookie.user.name,
        };
      }
    },
    goToOrder(orderId) {
      window.location = "/orders/" + orderId;
    },
    selectAllOrders() {
      if (this.selectedOrders != this.orders) {
        this.selectedOrders = this.orders;
      } else {
        this.selectedOrders = [];
      }
    },
    sortOrdersBy(sort) {
      if (sort === "oldest-newest") {
        this.sorting = "created_at";
        this.sortDirection = "asc";
      } else if (sort === "newest-oldest") {
        this.sorting = "created_at";
        this.sortDirection = "desc";
      }
      this.fetchOrders();
    },
    filterBySupplier(supplier) {
      if (supplier) {
        if (this.supplierFilter === supplier) {
          this.supplierFilter = null;
        } else {
          this.supplierFilter = supplier;
        }
      }
      this.fetchOrders();
    },
    clearSupplierFilters() {
      this.supplierFilter = null;
      this.orders = this.fetchOrders();
    },
    fetchOrders(paginationUrl) {
      this.loadingOrders = true;

      const url = new URL(
        paginationUrl
          ? paginationUrl
          : process.env.VUE_APP_API_URL + "/orders/api"
      );

      if (this.sorting) {
        url.searchParams.append("sort", this.sorting);
      }

      if (this.sortDirection) {
        url.searchParams.append("sort_direction", this.sortDirection);
      }

      if (this.statusFilter) {
        url.searchParams.append("status_filter", this.statusFilter);
      }

      if (this.typeFilter) {
        url.searchParams.append("type_filter", this.typeFilter);
      }

      if (this.userFilter) {
        url.searchParams.append("user_filter", this.userFilter.id);
      }

      if (this.searchTerm) {
        url.searchParams.append("searchTerm", this.searchTerm);
      }

      this.$axios.get(url.toString()).then(({ data }) => {
        this.pagination = data;
        this.orders = data.data;
        this.loading = false;
        this.loadingOrders = false;
      });
    },
    getOrderStatusColour(order) {
      if (order.status === "paid") {
        return "bg-success";
      } else if (order.status === "not_paid" || order.status === "partial") {
        return "bg-warning";
      } else if (order.status === "cancelled") {
        return "bg-danger";
      }

      return "bg-dark";
    },
    clearFilters() {
      this.statusFilter = "";
      this.typeFilter = "";
      this.userFilter = null;
    },
    toSupplierPayments() {
      this.$router.push("/supplier-payments");
    },
  },
  mounted() {
    this.loadFilters();
    this.fetchOrders();
  },
  filters: {
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatUnderScored(text) {
      if (text != null) {
        return text.replace(/_/g, " ");
      }
      return "-";
    },
  },
};
</script>

<style>
</style>
